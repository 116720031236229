<template>
  <section class="home-section-container">
    <DefaultContainer>
      <div :class="titleContainerClasses">
        <Text
          type="h4"
          weight="bold"
        >
          {{ title }}
        </Text>
        <NuxtLink
          v-if="seeAllPath"
          :to="seeAllPath"
        >
          <Text
            type="p2"
            weight="bold"
          >
            Lihat Semua
          </Text>
        </NuxtLink>
      </div>

      <slot></slot>
    </DefaultContainer>
  </section>
</template>

<script setup lang="ts">
  interface ISectionContainerProps {
    title: string
    seeAllPath?: string
  }

  const props = defineProps<ISectionContainerProps>()

  const titleContainerClasses = [
    'home-section-title',
    props.seeAllPath ? 'title-space-between' : 'title-center',
  ]
</script>

<style scoped lang="scss">
  .home-section-container {
    padding: 80px 110px;

    .home-section-title {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      a {
        color: $orange500;
      }
    }

    .title-space-between {
      justify-content: space-between;
    }

    .title-center {
      justify-content: center;
    }
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .home-section-container {
      padding: 40px 0 60px;

      .home-section-title {
        padding: 0 24px;
      }
    }
  }
</style>
